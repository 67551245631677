<template>
  <div>
    <title>SIM INVENTORY ~ ADD PRODUCTION PROCESS DATA DETAILS OF THE PRODUCTION PROCESS</title>
    <section class="content-header">
      <h1>
        Add Production Process Data Details of the production process
        <br />
        <h4>
          Please enter the transaction data for the detailed production process of the production process
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">
          Production Process Transaction Details of the production process
        </li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                Production Process Data Details of the production process
              </h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Production Number</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.prodnumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Production Number"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxprosesproduksi"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notesprosesprod"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="isidata.qtyprosprod"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.qtyprosprodi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/proses-produksi">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
       
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        prodnumb: "",
        idxprosesproduksi: "",
        notesprosesprod: "",
        qtyprosprod: "",
        qtyprosprodi: "",
      },
    };
  },
  created() {
    this.fetchstok();
    this.isidata.prodnumb = this.$route.params.noprod;
    this.isidata.idxprosesproduksi = this.$route.params.id;
  },
  methods: {
    fetchstok() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi_d_dari_proses_produksi/getstok_hasilk_produksibyid?id=" +
        this.$route.params.id;
      console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          //   console.log(resp);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, data not found",
              showConfirmButton: false,
            });
          } else {
            this.isidata.idxprosesproduksi = resp.data.data.id;
            this.isidata.qtyprosprod = resp.data.data.stok;
            this.isidata.qtyprosprodi = resp.data.data.stok;
            this.loading = false;
          }
        })
        /*  .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Peringatan",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        }); */
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async saveData() {
      this.loading = true;
      var prodnumber = this.isidata.prodnumb;
      var catatan = this.isidata.notesprosesprod;
      var jmlqty = this.isidata.qtyprosprod;
      if (prodnumber == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Production Number can't be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes Production Process can't be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (jmlqty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty Production Process Detail can't be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        if (this.isidata.qtyprosprod > this.isidata.qtyprosprodi) {
          swal({
            icon: "warning",
            title: "Warning",
            text: "Sorry, the amount you entered exceeds the existing stock",
            showConfirmButton: false,
          });
          this.loading = false;
        } else {
          const kodeuser = sessionStorage.getItem("kodeuser");
          const paramdata = {
            nomor_produksi: this.isidata.prodnumb,
            id_proses_produksi: this.isidata.idxprosesproduksi,
            notes: this.isidata.notesprosesprod,
            qty: this.isidata.qtyprosprod,
            kode_user: kodeuser,
          };
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPISaveData =
            this.$apiurl +
            "proses_produksi_d_dari_proses_produksi/saveproses_produksi_d_dari_proses_produksi";
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .post(urlAPISaveData, paramdata, { headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Save data successfully",
                  showConfirmButton: false,
                });
                this.$router.push({ name: "prosesproduksiheader" });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Save data failed",
                  showConfirmButton: false,
                });
                this.loading = false;
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false,
              });
              console.log(err);
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
